<div id="NisloModal" class="modal-mediumx mh-">
  <div class="project-panel">
    <div class="project-panel-body">
      <div class="pageSubHeader d-flex justify-content-between pb-3">
        <h3 class="page-title">{{projectTitle}} - Pricing</h3>
        <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="onCancelClick()"
        >
          <i class="fas fa-times" style="font-size: 14px"></i>
        </button>
      </div>
      <table class="project-detail-table mb-0 w-100 _tablePs-0 _tablePe-0" style="text-align: center;">
        <colgroup>
          <col class="word-head" />
          <col class="type-head" />
          <col class="amount-head" />
          <col class="price-head" />
          <col class="actions-head" />
        </colgroup>
        <thead>
        <tr>
          <th>Item</th>
          <th>Free of Charge</th>
          <th class="text-end">Base Word Count</th>
          <th class="text-start">Note</th>
          <th class="text-end">Price (£)</th>
          <th class="text-center">Action</th>
        </tr>
        </thead>
          <tbody>
          <tr>
            <td class="text-start">
              <b>Base Price</b>
            </td>
            <td></td>
            <td class="text-end">
              <b>{{data.baseWordCount ? data.baseWordCount : 0}}</b>
            </td>
            <td></td>
            <td class="text-end">
              <b>{{(basePrice ? basePrice : 0).toFixed(2)}}</b>
            </td>
            <td></td>
          </tr>
            <tr *ngFor="let cost of costs; let i = index">
              <td class="w-25">
              <ng-select #costType
                         class="form-control text-start"
                         bindLabel="label"
                         bindValue="id" required
                         placeholder="Select"
                         [searchable]="false"
                         [(ngModel)]="cost['additionalCostTypeId']"
                         [clearable]="false"
                         [dropdownPosition]="'bottom'"
                         (change)="selectedCostType($event, i)"
                         [ngClass]="{'is-invalid' : submitted && validations[i] && !validations[i]['additionalCostType']}">
                <ng-option *ngFor="let additionalCostType of additionalCostTypes;" [value]="additionalCostType">
                  {{additionalCostType.label}}
                </ng-option>
              </ng-select>
              </td>
              <td>
                <mat-checkbox
                        id="{{'freeOfCharge' + i}}"
                        class="form-control"
                        (change)="freeChecked($event, i)"
                        [(ngModel)]="cost['freeOfCharge']"
                >
                </mat-checkbox>
              </td>
              <td>
                <input
                        type="number"
                        class="text-end
                        form-control"
                        step="10"
                        [readonly]="!isMeasurable[i] ||
                        !cost['additionalCostTypeId']"
                        [(ngModel)]="cost['wordCount']"
                        [ngClass]="{ 'is-invalid': submitted && validations[i] && !validations[i]['wordCount']}"
                        (change)="calcRowPrice(i); checkPositiveWordCount(i)"
                        (keyup)="checkPositiveWordCount(i)"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" 
                >
              </td>
              <td>
                <textarea
                        type="text"
                        id="noteInput"
                        rows="1"
                        [(ngModel)]="cost['comment']"
                        class="form-control text-start"
                        [ngClass]="{ 'is-invalid': submitted && validations[i] && !validations[i]['note']}"
                >
                </textarea>
              </td>
              <td>
                <input type="number"
                       id="{{'price' + i}}"
                       class="text-end form-control"
                       step="10"
                       [readonly]="cost['freeOfCharge'] ||
                       isMeasurable[i] ||
                       !cost['additionalCostTypeId']"
                       [(ngModel)]="cost['price']"
                       [ngClass]="{ 'is-invalid': submitted && validations[i] && !validations[i]['price']}"
                       (keyup)="checkPositivePrice(i)"
                       (change)="checkPositivePrice(i)"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" 
                >
              </td>
              <td class="text-end">
                <button
                        class="btnProject btnProjectAdd btn btn-outline-danger"
                        (click)="removeCost(i)"
                >
                  <i class="fa fa-trash-alt pe-0"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="text-start">
                  <button
                          type="button"
                          class="btnProject btnProjectAdd btn btn-outline-primary h-100 w-100"
                          data-toggle="modal"
                          (click)="addRow()"
                  >
                    <i class="fas fa-plus"></i>Add New Cost
                  </button>
                </div>
              </td>
              <td colspan="3" class="text-end text-danger">
                Discount:
              </td>
              <td>
                <input
                        type="number"
                        class="text-end form-control text-danger"
                        (input)="calcTotalPrice()"
                        [(ngModel)]="discountTotal"
                >
              </td>
              <td>

              </td>
            </tr>
            <tr>
              <td class="text-end _table-bold-text" colspan="4">
                <b>Total Price:</b>
              </td>
              <td class="_table-bold-text text-center" colspan="2">
                <b>{{totalPrice | currency:  currency}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-end">
                <b>Internal Comment:</b>
              </td>
              <td colspan="5">
                <textarea class="form-control" cols="100" rows="5" [(ngModel)]="comment"></textarea>
              </td>
            </tr>
          <tr>
            <td class="text-end">
              <b>Comment to Customer:</b>
            </td>
            <td colspan="5">
              <textarea class="form-control" cols="100" rows="5" [(ngModel)]="customerComment"></textarea>
            </td>
          </tr>
          </tbody>
      </table>
      <div class="text-right pt-3">
        <button type="button" class="btn btn-white mr-3" data-dismiss="modal" (click)="onCancelClick()">
          Cancel
        </button>
        <button type="button" class="btnProject btnProjectAdd btn btn-blue" data-toggle="modal"
                (click)="isValidation(true)">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
