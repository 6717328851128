export const environment = {
  securityUrl: 'https://auth.unihelper.com/',
  apiUrl: 'https://api.pms.unihelper.com/',
  production: true,
  currency: 'GBP',
  firebaseConfig: {
    apiKey: 'AIzaSyA5Np3bTrrCPdClygYxhVkpaOsjDaFeAHc',
    authDomain: 'unihelpernotification-prod.firebaseapp.com',
    projectId: 'unihelpernotification-prod',
    storageBucket: 'unihelpernotification-prod.appspot.com',
    messagingSenderId: '220290056199',
    appId: '1:220290056199:web:14d1e92ff36086b30e6aa2',
    measurementId: 'G-V2XE1F9E6K'
  }
};
